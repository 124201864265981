import React, { useContext } from 'react'
import AuthContext from 'contexts/AuthContext'
import Link from 'elements/Link/Link'
import Title from 'elements/Typography/Title'
import Subtle from 'elements/Typography/Subtle'
import LoginForm from 'views/Auth/LoginForm'
import Auth from '@aws-amplify/auth'
import { md5Hash } from 'utils/hashUtils'
import { navigate } from 'gatsby'
import AltActionButton from 'elements/Button/AltActionButton'
import {
  useToast,
  Text

} from '@chakra-ui/core'
import {
  SERVICE_LIST_ROUTE,
  REGISTER_ROUTE
} from 'config/routes'

export default function Login () {
  const { setAuthenticated, setEmailHash, isAuthenticating, isAuthenticated, setAccountId, setUserId } = useContext(AuthContext)
  const toast = useToast()
  const notice = {
    title: 'Ooops!',
    description: 'There was a problem signing up - please reach out to support',
    status: 'error',
    isClosable: true
  }

  const onSubmit = async ({ email, password }) => {
    try {
      const CognitoUser = await Auth.signIn(email, password)
      const { username, attributes } = CognitoUser
      setEmailHash(md5Hash(attributes.email))
      setAuthenticated(true)
      setUserId(username)
      setAccountId(username)
      navigate(SERVICE_LIST_ROUTE)
    } catch (e) {
      toast({ ...notice, description: e.message })
    }
  }

  return (
    <>
      <Title label="Login">Login. <Subtle>Hi there.</Subtle></Title>
      { isAuthenticating ? null : isAuthenticated
        ? <Text>You are already logged in. Go to your <Link styled to={SERVICE_LIST_ROUTE}>dashboard</Link>.</Text>
        : <><LoginForm onSubmit={onSubmit}/>
          <AltActionButton to={REGISTER_ROUTE}> Don&apos;t have an account? Sign up now!</AltActionButton>
        </>
      }
    </>
  )
}
